<template>
    <div class="wh">
        
        <img class="logo" src="../assets/img/logo-icon.png" alt="">
        
        <div style="font-size:0.25rem;margin-top:0.14rem">{{$t('main.platMainb')}}</div>
        <img class="whz" src="../assets/img/whzzz.png" alt="">
        <div class="text" v-html="msg"></div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            msg:''
        }
    },
    mounted(){
        console.log(localStorage.getItem('maintenance'))
        this.msg = localStorage.getItem('maintenance')
    },
    methods:{

    }
}
</script>

<style scoped>
.wh{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.15rem;
}
.logo{
    width: 1.36rem;
    object-fit: contain;
}
.whz{
    width: 3.07rem;
    object-fit: contain;
}
.text{
    margin-top: 0.3rem;
    font-size: 0.14rem;
    text-align: center;
    white-space:pre-wrap;
}
</style>